:root {
  --hue: 223px;
  --bg: hsl(var(--hue), 90%, 95%);
  --fg: hsl(var(--hue), 90%, 5%);
  --trans-dur: 0.3s;
  font-size: calc(16px + (24px - 16px) * (690px - 320px) / (1280px - 320px));
}

.body {
  background-color: var(--bg);
  color: var(--fg);
  font: 16px/1.5 sans-serif;
  height: 100px;
  display: grid;
  place-items: center;
  transition: background-color var(--trans-dur);
}

main {
  padding: 24px 0;
}

.ip {
  width: 100px;
  height: 50px;
}

.ip__track {
  stroke: hsl(var(--hue), 90%, 90%);
  transition: stroke var(--trans-dur);
}

.ip__worm1,
.ip__worm2 {
  animation: worm1 2s linear infinite;
}

.ip__worm2 {
  animation-name: worm2;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 90%, 5%);
    --fg: hsl(var(--hue), 90%, 95%);
  }

  .ip__track {
    stroke: hsl(var(--hue), 90%, 15%);
  }
}

/* Animation */
@keyframes worm1 {
  from {
    stroke-dashoffset: 0;
  }

  50% {
    animation-timing-function: steps(1);
    stroke-dashoffset: -358;
  }

  50.01% {
    animation-timing-function: linear;
    stroke-dashoffset: 358;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes worm2 {
  from {
    stroke-dashoffset: 358;
  }

  50% {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -358;
  }
}

#loader-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(182, 177, 177, 0.253);
}

#loader-container1 {
  height: 30vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

#loader-container2 {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000da;
}
