@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@500;600&family=Oregano:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Roboto:wght@100;300;400&display=swap");
@import "@splidejs/react-splide/css";
@import "@splidejs/react-splide/css/skyblue";
@import "@splidejs/react-splide/css/sea-green";
@import "@splidejs/react-splide/css/core";
@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@400..700&family=Rubik+Scribble&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  -webkit-user-select: none;
  user-select: none;
  scroll-behavior: smooth;
}
.awa1 {
  display: none;
}

.swipperr {
  height: 60rem;
  width: 100%;
}

.swiper_image {
  margin: 1rem 0rem;
}

.swiper_image img {
  object-fit: cover;
}

.fwa {
  border-bottom: 1px solid #000;
}

.lrr {
  display: block;
}

.srr {
  display: none;
  margin: 0 auto;
  height: 100%;
  width: 60%;
  border-radius: 1rem;
}

.cssanimation,
.cssanimation span {
  animation-duration: 1.3s;
  animation-fill-mode: both;
}

.cssanimation span {
  display: inline-block;
}

.fadeInTop {
  animation-name: fadeInTop;
}

.fadeInBottom {
  animation-name: fadeInBottom;
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(300%);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #ffffff6f;
  display: flex;
  justify-content: center;
  align-items: center;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #000;
}

*::-webkit-scrollbar-thumb {
  background-color: #ffa600;
  border-radius: 20px;
}

.cursor {
  background-color: #ff491b;
}

.header_inameq {
  display: flex;
  gap: 1.5rem;
}

.header_logoimgq {
  position: relative;
  object-fit: cover;
  height: 2.2rem;
  cursor: pointer;
}

.header_logoimg22 {
  position: relative;
  object-fit: cover;
  height: 2.2rem;
}

.header_containerq {
  z-index: 100;
}

.header_logoimageq {
  padding: 2.5rem 3rem;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.6s;
}

.header_logoimage2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.6s;
  padding: 2.5rem 3rem;
  z-index: 100;
  background-color: rgb(15, 1, 15);
  color: #000;
}

.hamburger {
  display: none;
}

header.sticky {
  padding: 1.5rem 3rem;
  background: #000;
}

header .header_logoq {
  margin-top: 0.3rem;
  position: relative;
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  cursor: pointer;
}

header .header_logo2 {
  position: relative;
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  transition: 0.6s;
}

.sticky2 {
  padding: 1.5rem 3rem;
  background: #000;
  color: #fff !important;
}

header ul {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

header ul li {
  position: relative;
  list-style: none;
}

header ul li a {
  position: relative;
  text-decoration: none;
  margin: 0 0.8rem;
  color: #fff;
  font-size: 1.25rem;
  transition: 0.6s;
  letter-spacing: 0.2px;
  padding: 0.7rem 1.3rem;
  font-family: sans-serif;
  font-weight: 400;
}

.menuactive {
  background: #ffaf1b;
  color: #fff !important;
  border-radius: 0.4rem;
  transition: 0.5s;
}

header ul li a:hover {
  color: #ffaf1b;
  border-radius: 0.4rem;
  transition: 0.5s;
  cursor: pointer;
}

.blackbg {
  background-color: #000;
}

/* end of header */

/* start of about section */

.about_container {
  height: 100%;
}

.about_container1 {
  height: 100.1vh;
}

.about_banner {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url(../../public/images/scenary0-c.jpeg);
  background-size: cover;
  background-attachment: fixed;
}

.about_center_name {
  height: 100vh;
  width: 100%;
  color: white;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.73) 17%,
    rgba(0, 0, 0, 0.66) 35%,
    rgba(0, 0, 0, 0.55) 62%,
    rgba(0, 0, 0, 0.4) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about_center_namemq {
  display: none;
}

.about_name {
  font-family: "Josefin Sans", sans-serif;
  font-size: 6rem;
  font-weight: 300;
}

.about_namemq {
  font-family: "Josefin Sans", sans-serif;
  font-size: 6rem;
  font-weight: 300;
  margin-top: 1rem;
}

.about_descriptionmq {
  margin-top: 2rem;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
}

.about_description {
  margin-top: 2rem;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
}
.ca3-scroll-down-arrow {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2hldnJvbl90aGluX2Rvd24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBmaWxsPSJ3aGl0ZSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTE3LjQxOCw2LjEwOWMwLjI3Mi0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwYzAuMjcsMC4yNjgsMC4yNzEsMC43MDEsMCwwLjk2OWwtNy45MDgsNy44M2MtMC4yNywwLjI2OC0wLjcwNywwLjI2OC0wLjk3OSwwbC03LjkwOC03LjgzYy0wLjI3LTAuMjY4LTAuMjctMC43MDEsMC0wLjk2OWMwLjI3MS0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwTDEwLDEzLjI1TDE3LjQxOCw2LjEwOXoiLz48L3N2Zz4=);
  background-size: contain;
  background-repeat: no-repeat;
}

.ca3-scroll-down-link {
  cursor: pointer;
  height: 40px;
  width: 40px;
  line-height: 60px;
  position: absolute;
  bottom: 150px;
  color: #fff;
  font-size: 70px;
  z-index: 100;
  text-decoration: none;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  animation: ca3_fade_move_down 10s ease-in-out infinite;
}

/*animated scroll arrow animation*/
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -10px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}

.about_container_me {
  display: none;
}

.about_container_memq {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 10rem;
  margin-top: 10rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about_pic_container {
  width: 40%;
}

.pic_me {
  height: 80%;
  width: 63%;
  border-radius: 2rem;
  margin: 12.5% 0% 0% 20%;
}

.pic_memq {
  width: 14.5rem;
  height: 14.5rem;
  border-radius: 10rem;
  margin: 20.5% 0% 0% 0%;
}

.about_para_container {
  width: 60%;
}

.about_para_containermq {
  width: 100%;
}

.intro_con_title1 {
  font-size: 3rem;
  font-weight: 300;
  font-family: "Josefin Sans", sans-serif;
  text-align: center;
  margin-left: -20%;
}

.intro_con_titlemq {
  font-size: 3rem;
  font-weight: 300;
  font-family: "Josefin Sans", sans-serif;
  text-align: center;
}

.intro_para_container {
  margin: 0 10%;
  margin-left: -5%;
}

.intro_para_containermq {
  margin: 0 10%;
}

.into_para_1 {
  margin-top: 7.5%;
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  font-weight: 100;
}

.into_para_1 lnkd {
  color: #0077b5;
  text-decoration: none;
}

.into_para_1mq {
  margin-top: 4rem;
  text-align: center;
  font-size: 1.7rem;
  line-height: 2 !important;
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.into_para_1mq lnkd {
  color: #0077b5;
  text-decoration: none;
}

.into_para_1mq lnkd:hover {
  cursor: pointer;
}

.Section_what_I_Do {
  height: 100%;
  width: 100%;
  background-color: rgba(182, 177, 177, 0.253);
  padding-bottom: 5rem;
}

.wid_title {
  width: 100%;
}

.wid_title h1 {
  padding-top: 10rem;
  font-family: "Josefin Sans", sans-serif !important;
  font-size: 3rem;
  font-weight: 300;
  text-align: center;
}

.wid_des {
  display: flex;
  padding: 1rem 10rem;
  justify-content: center;
  gap: 15%;
  margin-top: 5rem;
  width: 100%;
  height: 100%;
  align-items: center;
}

.wid_des_webdev {
  height: 45rem;
}

.wid_des_bcdev {
  height: 45rem;
}

.bg_circle1 {
  background-color: #000;
  margin: auto;
  height: 17rem;
  width: 17rem;
  border-radius: 50%;
}

.bg_circle2 {
  background-color: #000;
  margin: auto;
  height: 17rem;
  width: 17rem;
  border-radius: 50%;
}

.wid_title_name {
  font-family: "Open Sans", sans-serif !important;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 5rem;
  text-align: center;
  letter-spacing: 0.2rem;
}

.wid_para_des {
  margin-top: 3rem;
  font-size: 1.5rem;
  font-family: sans-serif !important;
  font-weight: 300;
  line-height: 1.7;
  text-align: center;
  max-width: 120rem;
}

.footer_container {
  width: 100%;
  background-color: #000000e1;
  padding: 3rem 0rem;
}

.footer_cpyright {
  font-size: 1.5rem;
  font-family: "open Sans", sans-serif !important;
  font-weight: 100;
  color: white;
  align-items: center;
  text-align: center;
}

.footer_des {
  font-size: 1.5rem;
  padding-top: 1rem;
  font-family: "open Sans", sans-serif !important;
  font-weight: 100;
  color: white;
  text-align: center;
}

.codesvgpng {
  height: 9rem;
  width: 9rem;
  margin-left: 23%;
  margin-top: 24%;
}

.codesvgpng:hover {
  animation: rotate 0.5s linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.cpyright {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.8rem;
  height: 1.5rem;
  width: 2rem;
}

/* SKILLS PAGE */

.skills_container {
  height: 100%;
  background-color: rgba(182, 177, 177, 0.253);
}

.exp_container {
  height: 100%;
  background-color: rgba(182, 177, 177, 0.253);
}

.cnt_container {
  height: 100%;
  background-color: rgba(182, 177, 177, 0.253);
}

.res_container {
  height: 100%;
  background-color: rgba(182, 177, 177, 0.253);
}

.Accordion_123 {
  padding-top: 15rem;
  padding-bottom: 10rem;
}

.exp_Accordion_123 {
  padding-top: 15rem;
  padding-bottom: 10rem;
  min-height: 95vh;
  max-height: 100%;
}

.contact_container {
  min-height: 100vh;
  max-height: 100%;
  padding-top: 13rem;
  padding-left: 5rem;
  padding-right: 5rem;
  text-align: center;
}

.contact_container h1 {
  font-size: 3rem;
  font-weight: 300;
  font-family: "Josefin Sans", sans-serif;
  text-align: center;
  margin-bottom: 5rem;
}

.cnt_title {
  margin-top: 3rem;
  padding-bottom: 5rem;
  border-bottom: 3px solid #000;
}

.contactsdiv {
  display: flex;
  max-width: 150rem;
  margin: 0 auto;
  justify-content: space-between;
  padding: 0 10rem;
}

.emaildivision {
  padding: 15rem;
  background-color: #fff;
  margin: 0 -5rem;
  margin-bottom: 0rem;
}

.emaildivision a {
  margin-top: 3rem;
}

.emaildivision h1 {
  margin: 0 auto;
  font-size: 2.5rem;
  color: #000000;
  width: 100%;
  font-family: "Rajdhani", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.contact_location {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact_whatsapp {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact_linkedin {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emaildiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact_telegram {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact_icons {
  height: 4rem;
  margin-top: 2rem;
}

.contact_icons_newtab {
  margin-left: 0rem;
  height: 1.5rem;
  width: 3rem;
}

.contact_circle1 {
  height: 8rem;
  width: 8rem;
  background-color: #f8cfad;
  border-radius: 50%;
}

.contact_circle1:hover {
  background-color: #ff9e4f;
  transition: all 0.3s;
  cursor: pointer;
}

.contact_circle2 {
  height: 8rem;
  width: 8rem;
  background-color: #80d7a0;
  border-radius: 50%;
}

.contact_circle2:hover {
  background-color: #25d366;
  transition: all 0.3s;
  cursor: pointer;
}

.contact_circle3 {
  height: 8rem;
  width: 8rem;
  background-color: #88c4e4;
  border-radius: 50%;
}

.contact_circle3:hover {
  background-color: #0077b5;
  transition: all 0.3s;
  cursor: pointer;
}

.contact_circle4 {
  height: 8rem;
  width: 8rem;
  background-color: #cecb87;
  border-radius: 50%;
}

.contact_circle4:hover {
  background-color: #f0ea39;
  transition: all 0.3s;
  cursor: pointer;
}

.contact_circle5 {
  height: 8rem;
  width: 8rem;
  background-color: #75b4d3;
  border-radius: 50%;
}

.contact_circle5:hover {
  background-color: #0088cc;
  transition: all 0.3s;
  cursor: pointer;
}

.contact_title {
  margin-top: 3rem;
  font-size: 2rem !important;
  cursor: pointer;
}

.cmnfont h1 {
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.cmna {
  display: block;
  text-decoration: none;
  color: #000;
}

.hello_button {
  display: block;
  margin: 0 auto;
  padding: 1rem;
  width: 16rem;
  font-size: 2rem;
  font-weight: 100;
  cursor: pointer;
  border: 2px solid #000;
  background-color: transparent;
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.hello_button:hover {
  background-color: #ffaf1b;
  transition: all 0.3s ease-in-out;
}

.res_Accordion_123 {
  padding-top: 15rem;
  height: 100% !important;
}

.accordion-title {
  display: flex;
  margin: 0.3rem 5rem;
  font-size: 1.5rem;
  padding: 1.8rem;
  background-color: #f7f7f7;
  cursor: pointer;
  color: #000;
}

.accordion-titleact {
  display: flex;
  margin: 0.3rem 1.5rem;
  font-size: 1.5rem;
  padding: 1.8rem;
  cursor: pointer;
  color: #000;
  background-color: #f5d8a2;
  border: 0.5px solid #000;
}

.accordion-titleact1 {
  display: flex;
  margin: 0.3rem 1.5rem;
  font-size: 1.5rem;
  padding: 1.8rem;
  cursor: pointer;
  color: #000;
  border: 0.5px solid #000;
  background-color: #f5d8a2;
  border: 0.5px solid #000;
}

.accordion-titleact2 {
  display: flex;
  margin: 0.3rem 1.5rem;
  font-size: 1.5rem;
  padding: 1.8rem;
  cursor: pointer;
  color: #000;
  border: 0.5px solid #000;
  background-color: #f5d8a2;
}

.exp_accordion-title {
  display: flex;
  margin: 0.3rem 5rem;
  padding: 1.8rem;
  background-color: #f9f1e3;
  cursor: pointer;
  color: #000;
  border: 0.5px solid #000;
}

.exp_accordion-title1 {
  display: flex;
  margin: 0.3rem 5rem;
  padding: 1.8rem;
  background-color: rgb(245, 245, 245);
  cursor: pointer;
  color: #000;
  border: 0.5px solid #000;
}

.exp_accordion-title1:hover {
  background-color: #f1e6d0;
}

.exp_accordion-title1act {
  display: flex;
  margin: 0.3rem 5rem;
  padding: 1.8rem;
  background-color: #f5d8a2;
  cursor: pointer;
  color: #000;
  border: 0.5px solid #000;
}

.exp_accordion-title div {
  font-size: 1.8rem;
}

.plusminus {
  height: 1.2rem;
  margin-top: 0.6rem;
  margin-left: auto;
  margin-right: 3rem;
  margin-right: -1rem;
}

.plusminus1 {
  height: 1.6rem;
  margin-left: auto;
  margin-right: 3rem;
}

.frontend h2 {
  margin-top: 2rem;
  margin-left: 3rem;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}

.exp_frontend h2 {
  margin-top: 2rem;
  margin-left: 3rem;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}

.frontenddes {
  margin-top: 3.5rem;
  margin-left: 3rem;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
}

.exp_frontenddes h2 {
  margin-left: 3rem;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
}

.exp_frontenddes h6 {
  margin-left: 3rem;
  margin-bottom: 0.5rem;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
}

.exp_frontenddes {
  margin-top: 3.5rem;
  font-size: 1.5rem;
}

.openn {
  position: absolute;
  height: 3rem;
  margin-top: -0.5rem;
}

.newtab {
  height: 2.2rem;
}

.newtab1 {
  height: 10rem;
}

.skillcard {
  height: 5rem;
  width: 14rem;
  box-shadow: 0rem 0rem 0.4rem#0000007a;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  color: #000;
}

.skillcard:hover {
  scale: 1.05;
  transition: all 0.3s;
}

.skillcard1:hover {
  scale: 1.05;
  transition: all 0.3s;
}

.skillcard11:hover {
  scale: 1.05;
  transition: all 0.3s;
}

.skillcard112:hover {
  scale: 1.05;
  transition: all 0.3s;
}

.skillcard2:hover {
  scale: 1.05;
  transition: all 0.3s;
}

.skillcard3:hover {
  scale: 1.05;
  transition: all 0.3s;
}

.skillcard33:hover {
  scale: 1.05;
  transition: all 0.3s;
}

.skillcard4:hover {
  scale: 1.05;
  transition: all 0.3s;
}

.skillcardsp:hover {
  scale: 1.05;
  transition: all 0.3s;
}

.skillcard44:hover {
  scale: 1.05;
  transition: all 0.3s;
}

.skillcard5:hover {
  scale: 1.05;
  transition: all 0.3s;
}

.skillcard1 {
  height: 5rem;
  width: 16rem;
  box-shadow: 0rem 0rem 0.4rem #0000007a;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  color: #000;
}

.skillcard11 {
  height: 5rem;
  width: 17.5rem;
  box-shadow: 0rem 0rem 0.4rem #0000007a;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  color: #000;
}

.skillcard112 {
  height: 5rem;
  width: 22.5rem;
  box-shadow: 0rem 0rem 0.4rem #0000007a;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  color: #000;
}

.skillcard2 {
  height: 5rem;
  width: 15rem;
  box-shadow: 0rem 0rem 0.4rem#0000007a;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  color: #000;
}

.skillcard3 {
  height: 5rem;
  width: 25rem;
  box-shadow: 0rem 0rem 0.4rem #0000007a;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  color: #000;
}

.skillcard33 {
  height: 5rem;
  width: 18rem;
  box-shadow: 0rem 0rem 0.4rem #0000007a;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  color: #000;
}

.skillcard4 {
  height: 5rem;
  width: 21rem;
  box-shadow: 0rem 0rem 0.4rem #0000007a;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  color: #000;
}

.skillcard44 {
  height: 5rem;
  width: 17rem;
  box-shadow: 0rem 0rem 0.4rem #0000007a;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  color: #000;
}

.skillcardsp {
  height: 5rem;
  width: 25rem;
  box-shadow: 0rem 0rem 0.4rem #0000007a;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  color: #000;
}

.skillcard5 {
  height: 5rem;
  width: 19rem;
  box-shadow: 0rem 0rem 0.4rem #0000007a;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  color: #000;
}

.sc_imgg {
  width: 6rem;
}

.sc_imgg1 {
  width: 6rem;
  background-color: rgb(236, 187, 169);
}

.sc_imgg121 {
  width: 6rem;
  background-color: rgb(196, 218, 136);
}

.sc_imgg1n {
  width: 6rem;
  background-color: rgb(169, 180, 236);
}

.sc_imgg1nb {
  width: 9rem;
  background-color: rgb(169, 226, 236);
}

.sc_imgg1m {
  width: 6rem;
  background-color: rgb(169, 236, 185);
}

.sc_imgg1mm {
  width: 6rem;
  background-color: rgb(210, 236, 169);
}

.sc_imgg111 {
  width: 6rem;
  background-color: rgb(199, 169, 236);
}

.sc_imgg2 {
  width: 6rem;
  background-color: rgb(179, 179, 228);
}

.sc_imgg3 {
  width: 6rem;
  background-color: rgb(171, 243, 245);
}

.sc_imgg33 {
  width: 6rem;
  background-color: rgb(245, 171, 171);
}

.sc_imgg31 {
  width: 6rem;
  background-color: rgb(171, 218, 245);
}

.sc_imgg4 {
  width: 6rem;
  background-color: rgba(245, 219, 147, 0.93);
}

.sc_imgg44 {
  width: 7rem;
  background-color: rgba(245, 239, 147, 0.786);
}

.sc_imgg445 {
  width: 9rem;
  background-color: rgba(147, 245, 207, 0.786);
}

.sc_imgg5 {
  width: 6rem;
  background-color: rgb(98, 163, 98);
}

.sc_imgg6 {
  width: 6rem;
  background-color: rgb(234, 240, 232);
}

.sc_imgg7 {
  width: 6rem;
  background-color: rgb(248, 224, 204);
}

.sc_imgg8 {
  width: 6rem;
  background-color: rgba(10, 10, 10, 0.63);
}

.sc_imgg9 {
  width: 6rem;
  background-color: rgba(236, 236, 165, 0.63);
}

.sc_imgg91 {
  width: 9rem;
  background-color: rgba(236, 165, 224, 0.63);
}

.sc_imgg912 {
  width: 9rem;
  background-color: rgba(165, 236, 203, 0.63);
}

.sc_imgg10 {
  width: 6rem;
  background-color: rgb(196, 173, 238);
}

.sc_imgg101 {
  width: 6rem;
  background-color: rgb(173, 238, 200);
}

.sc_imgg11 {
  width: 6rem;
  background-color: rgb(241, 188, 184);
}

.sc_imgg111 {
  width: 6rem;
  background-color: rgb(220, 216, 216);
}

.sc_imgg12 {
  width: 6rem;
  background-color: rgb(187, 206, 204);
}

.sc_imgg13 {
  width: 6rem;
  background-color: rgb(145, 164, 252);
}

.sc_imgg14 {
  width: 6rem;
  background-color: rgb(114, 201, 121);
}

.sc_imgg15 {
  width: 6rem;
  background-color: rgb(248, 243, 170);
}

.sc_imgg16 {
  width: 6rem;
  background-color: rgb(238, 208, 155);
}

.sc_imgg17 {
  width: 6rem;
  background-color: rgb(238, 185, 155);
}

.sc_imgg171 {
  width: 6rem;
  background-color: rgb(155, 217, 238);
}

.sc_imgg18 {
  width: 6rem;
  background-color: rgb(228, 221, 216);
}

.sc_imgg19 {
  width: 6rem;
  background-color: rgb(191, 205, 135);
}

.sc_imgg20 {
  width: 6rem;
  background-color: rgb(135, 205, 203);
}

.Html5pic {
  height: 3.8rem;
  margin-top: 0.6rem;
  margin-left: 1rem;
}

.Twpic {
  height: 3.8rem;
  margin-top: 0.6rem;
  margin-left: 0.8rem;
}

.SCSpic {
  height: 3.8rem;
  margin-top: 0.6rem;
  margin-left: 0.8rem;
}

.CSS3pic {
  height: 3.8rem;
  margin-top: 0.6rem;
  margin-left: 1rem;
}

.BSpic {
  height: 3.8rem;
  margin-top: 0.4em;
  margin-left: 1rem;
}

.RSpic {
  height: 3.8rem;
  margin-top: 0.4em;
  margin-left: 0.8rem;
}

.JSpic {
  height: 3.5rem;
  margin-top: 0.8rem;
  margin-left: 1.1rem;
}

.DSpic {
  height: 3.8rem;
  margin-top: 0.6rem;
  margin-left: 0.6rem;
}

.nodepic {
  height: 3.8rem;
  margin-top: 0.6rem;
  margin-left: 0.9rem;
}

.pythonpic {
  height: 3.8rem;
  margin-top: 0.6rem;
  margin-left: 0.9rem;
}

.APIpic {
  height: 3.8rem;
  margin-top: 0.6rem;
  margin-left: 0.9rem;
}

.Reactpic {
  height: 3.8rem;
  margin-top: 0.4em;
  margin-left: 1rem;
}

.Expresspic {
  height: 2rem;
  margin-top: 1em;
  margin-left: 1.2rem;
}

.Rspic {
  height: 4rem;
  margin-top: 0.5rem;
  margin-left: 1.1rem;
}

.Jquerypic {
  height: 3rem;
  margin-top: 1rem;
  margin-left: 1.4rem;
}

.tspic {
  height: 3rem;
  margin-top: 1rem;
  margin-left: 1.4rem;
}

.Ethereumpic {
  height: 3.8rem;
  margin-top: 0.6rem;
  margin-left: 0.9rem;
}

.Scpic {
  height: 3.5rem;
  margin-top: 0.8rem;
  margin-left: 0.7rem;
}

.Soliditypic {
  height: 4rem;
  margin-top: 0.5rem;
  margin-left: 1rem;
}

.VSCodepic {
  height: 3.8rem;
  margin-top: 0.4em;
  margin-left: 0.9rem;
}
.MongoDBpic {
  height: 3rem;
  margin-top: 1rem;
  margin-left: 1.3rem;
}

.SaaSpic {
  height: 4rem;
  margin-top: 0.6rem;
  margin-left: 1rem;
}

.BaaSpic {
  height: 3rem;
  margin-top: 1rem;
  margin-left: 1.3rem;
}

.Gitpic {
  height: 4rem;
  margin-left: 1.1rem;
  margin-top: 0.5rem;
}

.Gitpic11 {
  height: 3rem;
  margin-left: 1.4rem;
  margin-top: 0.7rem;
}

.Clang {
  height: 3rem;
  margin-left: 1.3rem;
  margin-top: 1rem;
}

.apip {
  height: 4rem;
  margin-left: 0.9rem;
  margin-top: 0.5rem;
}

.apipsp {
  height: 4rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  scale: 0.9;
}

.NJS {
  height: 4rem;
  margin-left: 0.9rem;
  margin-top: 0.5rem;
}

.GAI {
  height: 4rem;
  margin-left: 0.3rem;
  margin-top: 0.5rem;
}

.py {
  height: 4rem;
  margin-left: 1.1rem;
  margin-top: 0.5rem;
}

.Gcppic {
  height: 5rem;
  margin-top: 0.1rem;
  margin-left: 0.3rem;
}

.sc_text {
  width: 8rem;
  padding: 1.5rem;
  font-family: "Be Vietnam Pro", sans-serif;
}

.sc_text1 {
  width: 8rem;
  padding-left: 1rem;
  padding-top: 1.5rem;
  font-family: "Be Vietnam Pro", sans-serif;
}

.sc_text2 {
  width: 8rem;
  padding-left: 1.8rem;
  padding-top: 1.5rem;
  font-family: "Be Vietnam Pro", sans-serif;
}

.sc_text3 {
  width: 10rem;
  padding-left: 1rem;
  padding-top: 1.5rem;
  font-family: "Be Vietnam Pro", sans-serif;
}

.sc_text4 {
  width: 18rem;
  padding-left: 1rem;
  padding-top: 1.5rem;
  font-family: "Be Vietnam Pro", sans-serif;
}

.sc_text5 {
  width: 18rem;
  padding-left: 1rem;
  padding-top: 1.5rem;
  font-family: "Be Vietnam Pro", sans-serif;
}

.sc_text44 {
  width: 18rem;
  padding-left: 0.5rem;
  padding-top: 1.5rem;
  font-family: "Be Vietnam Pro", sans-serif;
}

.accordion-content {
  background-color: rgb(245, 245, 245);
  margin: 0rem 5rem;
  border: 0.5px solid #000;
}

.accordion-content2 {
  background-color: rgb(245, 245, 245);
  padding-bottom: 5rem;
  margin: 0rem 5rem;
  border: 0.5px solid #000;
}

.aniscroll {
  height: 0;
  overflow: hidden;
  transition: 1s ease;
}

.aniscroll.show1 {
  height: max-content;
}

.aniscroll1 {
  height: 0rem;
  overflow: hidden;
  transition: height 1s ease;
}

.aniscroll1.show1 {
  height: max-content;
}

.aniscroll2 {
  height: 0rem;
  overflow: hidden;
  transition: height 1s ease;
}

.aniscroll2.show1 {
  height: max-content;
}

.exp_accordion-content2 {
  background-color: rgba(255, 255, 255, 0.332);
  margin: 0rem 5rem;
}

.exp_accordion-content22 {
  background-color: rgba(182, 177, 177, 0);
  margin: 0rem 5rem;
}

.accordion-content1 {
  background-color: rgb(245, 245, 245);
  padding-top: 5rem;
  margin: 0rem 5rem;
  border: 0.5px solid #000;
}

.accdesbox1 {
  display: flex;
}
.exp_accdesbox1 {
  display: flex;
  padding-bottom: 5rem;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
}

.awardsclass {
  text-align: center;
}

.awardsclass h6 {
  font-size: 1.8rem;
  font-weight: 400;
}

.awardheading {
  margin-bottom: 2rem;
}

.frontend {
  width: 25%;
  padding-top: 3rem;
  padding-left: 5%;
}

.frontend h2 {
  text-align: start;
}

.exp_frontend h2 {
  text-align: start;
}

.frontenddes {
  width: 75%;
  display: flex;
  gap: 3rem;
}

.accdesbox {
  height: 100%;
  width: 100%;
  padding-left: 2.5%;
  padding-right: 2.5%;
}
.mqhidden {
  display: none;
}

.accdesbox2 {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 2.5%;
  padding-right: 2.5%;
}

.Accordion_123 h1 {
  font-size: 3rem;
  font-weight: 300;
  font-family: "Josefin Sans", sans-serif;
  text-align: center;
  margin-bottom: 8rem;
}

.exp_Accordion_123 h1 {
  font-size: 3rem;
  font-weight: 300;
  font-family: "Josefin Sans", sans-serif;
  text-align: center;
  margin-bottom: 8rem;
}

.res_Accordion_123 h1 {
  font-size: 3rem;
  font-weight: 300;
  font-family: "Josefin Sans", sans-serif;
  text-align: center;
  margin-bottom: 4rem;
}

.Certifications {
  width: 100%;
  margin-bottom: 8rem;
}

.Certifications h1 {
  text-align: center;
  font-size: 3rem;
  font-weight: 300;
  font-family: "Josefin Sans", sans-serif;
}

.cards {
  margin-top: 8rem;
  display: grid;
  gap: 5rem;
}

.card {
  color: white;
  overflow: hidden;
  height: 20rem;
  width: 33rem;
  border-radius: 0.5rem;
  box-shadow: 0.2rem 0.2rem 0.8rem #0000007a;
}

.card_fh {
  height: 50%;
  cursor: pointer;
  text-align: center;
  background-color: dodgerblue;
}

.imamg {
  height: 7rem;
  margin-top: 1.5rem;
}

.clr1 {
  background-color: rgb(233, 147, 180);
}

.clr2 {
  background-color: rgb(215, 238, 162);
}

.clr3 {
  background-color: rgb(147, 199, 233);
}

.clr4 {
  background-color: rgb(213, 147, 233);
}

.clr5 {
  background-color: rgb(180, 147, 233);
}

.clr6 {
  background-color: rgb(233, 220, 147);
}

.clr7 {
  background-color: rgb(233, 147, 147);
}
.clr8 {
  background-color: rgb(233, 147, 214);
}

.clr9 {
  background-color: rgb(160, 147, 233);
}

.clr10 {
  background-color: rgb(233, 189, 147);
}

.clr11 {
  background-color: rgb(216, 147, 233);
}

.clr12 {
  background-color: rgb(227, 233, 147);
}

.card .card_sh {
  font-size: 1.5rem;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 600;
  padding: 1rem;
  text-align: center;
  color: #000;
}

.card_sh p {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 300;
}

.card_sh {
  height: 50%;
}

.hiddencard {
  display: none;
}

.visiblecard {
  display: block;
}

.hiddencardn {
  display: none;
}

.view_more h1 {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: "Lato", sans-serif;
  color: #000;
}

.view_less h1 {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: "Lato", sans-serif;
  color: #000;
}

.view_more {
  margin-top: 5rem;
  cursor: pointer;
}

.view_less {
  margin-top: 5rem;
  cursor: pointer;
}

.typecard {
  display: none;
  margin-top: 3rem;
  cursor: pointer;
}

.languages {
  display: flex;
  width: 100%;
  padding-bottom: 3rem;
}

.langv1 {
  width: 10%;
  margin-top: 0.7rem;
}

.langv1 h1 {
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  text-align: end;
}

.languages h2 {
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  color: #828282;
}

.langv3 {
  width: 35%;
  margin-left: -15%;
  margin-top: 0.5rem;
}

.lang1 span {
  color: #ff491b;
  font-weight: 600;
}

.progressbar-wrapper {
  width: 65%;
}

.progressbar {
  display: flex;
}

.progressbar li {
  list-style-type: none;
  width: 20%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}

.progressbar li:before {
  width: 25px;
  height: 25px;
  content: "";
  line-height: 60px;
  border: 1px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 3px auto;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  background-color: #fff;
}

.progressbar li:after {
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #ffa600;
  top: 15px;
  left: -50%;
  z-index: 0;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active {
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.progressbar li.active:before {
  border-color: #ffa600;
  background: #ffa600;
}

.progressbar li.active + li:after {
  background-color: #ffa600;
}

.progressbar li.active:before {
  background: #ffa600 url(/public/images/tick.png) no-repeat center center;
  background-size: 100%;
}

.progressbar li::before {
  background: #fff url(/public/images/tick.png) no-repeat center center;
  background-size: 100%;
}

.ips_box {
  height: 100%;
  width: 100%;
  padding: 2rem;
  border-radius: 2rem;
  box-shadow: 0rem 0rem 0.4rem #0000007a;
}

.ips_box2 {
  height: 100%;
  width: 20rem;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0rem 0rem 0.4rem #0000007a;
}

.ips_box3 {
  height: 100%;
  width: 20rem;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0rem 0rem 0.4rem #0000007a;
}

.ips_box4 {
  height: 100%;
  width: 20rem;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0rem 0rem 0.4rem #0000007a;
}

.ips_box5 {
  height: 100%;
  width: 20rem;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0rem 0rem 0.4rem #0000007a;
}

.ips_box_title {
  height: 2rem;
}

.ips_box_title h1 {
  text-align: center;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
}

.ips_box_des p {
  font-size: 1.5rem;
  font-weight: 300;
}

.ips_box2:hover {
  scale: 1.03;
  transition: all 0.3s ease-in-out;
}

.ips_box3:hover {
  scale: 1.03;
  transition: all 0.3s ease-in-out;
}

.ips_box4:hover {
  scale: 1.03;
  transition: all 0.3s ease-in-out;
}

.ips_box5:hover {
  scale: 1.03;
  transition: all 0.3s ease-in-out;
}

.immmg {
  margin-top: 5%;
  width: 6rem;
}

.immmg1 {
  margin-top: 1%;
  width: 10rem;
}

.gcpp {
  height: 6rem;
  margin-top: 2rem;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.card_fh:hover .overlay {
  top: 0;
  height: 50%;
  background-color: #000;
  filter: opacity(80%);
  cursor: pointer;
}

.text {
  color: white;
  font-size: 1.8rem;
  letter-spacing: 0.2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  text-decoration: none;
}

.at_title {
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 2rem !important;
}

.line-1 {
  position: relative;
  top: 50%;
  color: #7c7c7c;
  width: 28em;
  margin: 0 auto;
  border-right: 2px solid rgba(255, 255, 255, 0.75);
  font-size: 180%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
  cursor: default;
}

/* Animation */
.anim-typewriter {
  animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 28em;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0);
  }
  to {
    border-right-color: transparent;
  }
}

.lnnkd {
  text-decoration: none;
  color: #0077b5;
  cursor: pointer;
}

.certi_lnkd {
  text-decoration: none;
  color: #0077b5;
  cursor: pointer;
}
.accordion-title:hover {
  background-color: #f1e6d0;
  border: 1px solid #000;
}

.exp_at_title {
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 2rem !important;
}

.exp_at_title1 {
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 2rem !important;
}

.TCS_logo {
  height: 8rem;
  width: 20rem;
}

.alice-image:hover {
  scale: 1;
}

.splide {
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.SplideSlide img {
  border-radius: 0.8rem;
}

.buttoncv {
  margin: 3rem -0.5rem;
  width: 20rem;
  justify-content: center;
  display: inline-block;
  padding: 8px;
  border: 1px solid #4f4f4f;
  background-color: #ffaf1b;
  border-radius: 4px;
  transition: all 0.2s ease-in;
  position: relative;
  overflow: hidden;
  font-size: 19px;
  cursor: pointer;
  color: black;
  z-index: 1;
}

.dwnbtn {
  margin: 0 auto;
}

.buttoncv:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(1) scaleX(1.25);
  top: 100%;
  width: 140%;
  height: 180%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.55 0.1s cubic-bezier (0.55, 0, 0.1, 1);
  z-index: -1;
}

.buttoncv:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: #ffa600;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.buttoncv:hover {
  color: #000;
  border: 1px solid #ffa600;
}

.buttoncv:hover:after {
  top: -45%;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  background-color: #75f7e8;
}

.resumecontainer {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  display: flex;
  gap: 5rem;
  justify-content: center;
}

.pdfviewerr {
  margin: 0 auto;
  height: 60vh;
  width: 60%;
  border-radius: 1rem;
}

.engres {
  display: flex;
  flex-direction: column;
  height: 25rem;
  width: 25rem;
  padding: 3rem;
  border-radius: 2.2rem;
  box-shadow: rgba(50, 50, 93, 8.25) 8px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  background-color: rgba(182, 177, 177, 0.253);
}

.gerres {
  height: 25rem;
  width: 25rem;
  padding: 3rem;
  border-radius: 2.2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  background-color: rgba(182, 177, 177, 0.253);
}

.cv1 {
  margin-top: -30%;
  height: 40%;
  width: 100%;
}

.popup {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
}

.popup_inner {
  position: absolute;
  left: 10%;
  right: 10%;
  top: 10%;
  bottom: 10%;
  margin: auto;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.993);
  text-align: center;
  overflow-y: scroll;
}

.buttoncl {
  position: absolute;
  cursor: pointer;
  top: 1rem;
  right: 2rem;
  width: 2em;
  height: 2em;
  border: none;
  background: transparent;
  border-radius: 50%;
  transition: background 0.5s;
}

.X {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2em;
  height: 1.5px;
  background-color: #000;
  transform: translateX(-50%) rotate(45deg);
}

.Y {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2em;
  height: 1.5px;
  background-color: #000;
  transform: translateX(-50%) rotate(-45deg);
}

.close {
  position: absolute;
  display: flex;
  padding: 0.8rem 1.5rem;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
  top: -90%;
  left: 50%;
  width: 3em;
  height: 1.7em;
  font-size: 12px;
  background-color: rgb(19, 22, 24);
  color: rgb(187, 229, 236);
  border: none;
  border-radius: 3px;
  pointer-events: none;
  opacity: 0;
}

.buttoncl:hover > .close {
  animation: close 0.2s forwards 0.25s;
}

@keyframes close {
  100% {
    opacity: 1;
  }
}

.popupimg {
  height: 80%;
  width: 80%;
  margin: 4% 0%;
  border-radius: 2rem;
}

/* Mobile: 360 x 640
Mobile: 375 x 667
Mobile: 360 x 720
iPhone X: 375 x 812
Pixel 2: 411 x 731
Tablet: 768 x 1024
Laptop: 1366 x 768
High-res laptop or desktop: 1920 x 1080 */

/* RESPONSIVE SECTION STARTS */
/* NAVIGATION BAR  */

@media only screen and (max-width: 950px) {
  .resumecontainer {
    scale: 0.9;
    margin-top: 3rem;
    gap: 5rem;
  }
  .res_Accordion_123 {
    height: 100vh;
    padding-bottom: 10rem;
  }

  .splide_container {
    scale: 1;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    margin-top: -0.5rem;
  }

  .hamburger .line {
    width: 3rem;
    height: 0.3rem;
    background: #fefefe;
    margin-top: 0.5rem;
  }

  header.sticky {
    padding: 1.5rem 2rem 1.5rem 1.4rem;
  }

  header.sticky2 {
    padding: 1.5rem 2rem 1.5rem 1.4rem;
  }

  .header_logoimageq,
  .header_logoimage2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.6s;
    padding: 1.5rem 2rem 1.5rem 1.4rem;
    z-index: 100;
    background-color: #000;
  }

  header ul {
    height: 0rem;
    opacity: 0;
    position: absolute;
    top: 0rem;
    left: 0;
    right: 0;
    width: 100vw;
    background: #000;
    transition: 0.5s;
    overflow: hidden;
  }

  header ul {
    display: block;
    margin-top: 5.45rem;
  }
  header ul li {
    width: fit-content;
    margin: 3rem auto auto;
  }

  .header_ul.active {
    height: 20rem;
    opacity: 1;
  }
}

/* MOBILE max width 780px*/
@media screen and (min-width: 951px) {
  .about_para_containermq {
    width: 50%;
  }
}

@media screen and (max-width: 780px) {
  .about_banner {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url(../../public/images/portrait0-c.jpeg);
    background-size: cover;
    background-attachment: fixed;
  }
  .lrr {
    display: none;
  }

  .srr {
    display: block;
  }
  .fwa {
    border-bottom: 1px solid #000;
  }
  .awa {
    display: none;
  }
  .pdfviewerr {
    margin: 0 auto;
    height: 50vh;
    width: 90%;
    border-radius: 1rem;
  }
  .buttoncv {
    width: 15rem;
  }
  .line-1 {
    display: none;
  }
  .emaildivision {
    padding: 5rem;
    background-color: #fff;
    margin: 0 -5rem;
    margin-bottom: 0rem;
  }

  .contactsdiv {
    display: flex;
    flex-direction: column;
    max-width: 100rem;
    margin: 0 auto;
    justify-content: center;
    padding: 0 0rem;
  }

  .resumecontainer {
    scale: 0.9;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-items: center;
  }

  .res_Accordion_123 {
    height: 100vh;
    padding-bottom: 10rem;
  }

  .about_center_name {
    display: none;
  }

  .about_center_namemq {
    display: block;
    height: 100vh;
    width: 100%;
    color: white;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.73) 17%,
      rgba(0, 0, 0, 0.66) 35%,
      rgba(0, 0, 0, 0.55) 62%,
      rgba(0, 0, 0, 0.4) 100%
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about_name {
    display: block;
    font-family: "Josefin Sans", sans-serif;
    font-size: 6rem;
    font-weight: 300;
  }
  .about_container_me {
    display: none;
  }

  .about_container_memq {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-bottom: 5rem;
    margin-top: 7rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .intro_con_title1 {
    display: none;
  }

  .intro_con_titlemq {
    display: block;
    font-size: 2rem;
    font-weight: 300;
    font-family: "Josefin Sans", sans-serif;
    text-align: center;
  }

  .wid_des {
    display: flex;
    flex-direction: column;
    padding: 1rem 5rem;
    justify-content: center;
    gap: 5rem;
    margin-top: 5rem;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  .wid_des_webdev {
    height: 150%;
    width: 100%;
  }

  .wid_des_bcdev {
    height: 150%;
    width: 100%;
  }

  .wid_title_name {
    font-family: "Open Sans", sans-serif !important;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 5rem;
    text-align: center;
    letter-spacing: 0.2rem;
  }

  .wid_title h1 {
    padding-top: 7rem;
    font-family: "Josefin Sans", sans-serif !important;
    font-size: 3rem;
    font-weight: 300;
    text-align: center;
  }

  .accdesbox1 {
    display: flex;
    flex-direction: column;
  }

  .frontend h2 {
    margin-top: 2rem;
    margin-left: 0rem;
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
  }

  .frontend {
    width: 100%;
    padding-top: 1.5rem;
    padding-left: 0%;
  }

  .frontenddes {
    margin-top: 3rem;
    margin-left: 0rem;
    padding-left: 3rem;
    padding-right: 3rem;
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    zoom: 0.9;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .accordion-title {
    display: flex;
    margin: 0.3rem 1.5rem;
    font-size: 1.5rem;
    padding: 1.8rem;
    background-color: #f7f7f7;
    cursor: pointer;
    color: #000;
  }

  .accordion-content2 {
    background-color: rgb(245, 245, 245);
    padding-bottom: 5rem;
    margin: 0rem 1.5rem;
  }

  .accordion-content1 {
    background-color: rgb(245, 245, 245);
    margin: 0rem 1.5rem;
  }

  .accordion-content {
    background-color: rgb(245, 245, 245);
    margin: 0rem 1.5rem;
  }

  .accdesbox {
    display: none;
  }

  .mqhidden {
    display: block;
  }

  .langv1 {
    width: 100%;
    margin-top: 0.7rem;
    height: 4rem;
  }

  .langv1 h1 {
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
  }

  .languages {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    width: 100%;
    padding-bottom: 3rem;
  }

  .progressbar-wrapper {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: none;
  }

  .progressbar li {
    list-style-type: none;
    width: 25%;
    float: left;
    font-size: 12px;
    text-align: center;
    position: relative;
    text-transform: uppercase;
    color: #7d7d7d;
  }

  .langv3 {
    width: 100%;
    margin-left: 0%;
    margin-top: 0.5rem;
    justify-content: center;
  }

  .accdesbox2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .cards {
    margin-top: 8rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 5rem;
  }

  .card {
    color: white;
    overflow: hidden;
    height: 20rem;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: 0.2rem 0.2rem 0.8rem#0000007a;
  }

  .exp_accdesbox1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0rem;
  }

  .exp_frontend img {
    text-align: center;
    margin-top: 3rem;
  }

  .exp_frontenddes h2 {
    margin-left: 3rem;
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
  }

  .exp_frontenddes h6 {
    margin-left: 3rem;
    margin-bottom: 0.5rem;
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.6rem;
    font-weight: 300;
  }
}

@media screen and (max-width: 500px) {
  .awa1 {
    display: none !important;
  }
}

@media screen and (min-width: 550px) and (max-width: 780px) {
  .alice-image {
    height: 12rem;
    scale: 1;
  }

  .about_center_namemq {
    display: none;
  }

  .about_center_name {
    display: block;
    height: 100vh;
    width: 100%;
    color: white;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.73) 17%,
      rgba(0, 0, 0, 0.66) 35%,
      rgba(0, 0, 0, 0.55) 62%,
      rgba(0, 0, 0, 0.4) 100%
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .accordion-title {
    display: flex;
    margin: 0.3rem 1.5rem;
    font-size: 1.5rem;
    padding: 1.8rem;
    background-color: #f7f7f7;
    cursor: pointer;
    color: #000;
  }

  .accordion-content2 {
    background-color: rgb(245, 245, 245);
    padding-bottom: 5rem;
    margin: 0rem 1.5rem;
  }

  .cards {
    margin-top: 5rem;
    display: grid;
    gap: 2rem;
    justify-content: center;
  }

  .card {
    scale: 0.9;
    width: 100%;
  }

  .accdesbox2 {
    scale: 0.9;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .accdesbox {
    scale: 0.9;
  }

  .accordion-content1 {
    background-color: rgb(245, 245, 245);
    padding-top: 0rem;
  }
}

@media screen and (min-width: 550px) and (max-width: 768px) {
  .alice-image {
    height: 12rem;
    scale: 0.8;
  }
}

@media screen and (min-width: 500px) and (max-width: 549px) {
  .pdfviewerr {
    margin: 0 auto;
    height: 50vh;
    width: 100%;
    border-radius: 1rem;
  }
  .buttoncv {
    width: 15rem;
  }
  .pdfviewerr {
    margin: 0 auto;
    height: 50vh;
    width: 100%;
    border-radius: 1rem;
  }
  .buttoncv {
    width: 15rem;
  }
  .alice-image {
    height: 14rem;
    scale: 0.8;
  }

  .cards {
    margin-top: 5rem;
    display: grid;
    gap: 3rem;
    justify-content: center;
  }

  .card {
    scale: 0.9;
    color: white;
    overflow: hidden;
    height: 20rem;
    width: 100%;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: 0.2rem 0.2rem 0.8rem #0000007a;
  }

  .frontenddes {
    scale: 1;
    margin-top: 3rem;
    margin-left: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .skillcard {
    height: 5rem;
    width: 14rem;
    box-shadow: 0rem 0rem 0.4rem #0000007a;
    border-radius: 0.4rem;
    overflow: hidden;
    display: flex;
    color: #000;
  }

  .accdesbox2 {
    scale: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .accdesbox {
    scale: 0.9;
  }

  .accordion-content1 {
    background-color: rgb(245, 245, 245);
    padding-top: 0rem;
  }
}

@media screen and (min-width: 361px) and (max-width: 499px) {
  .exp_frontenddes {
    scale: 0.8;
  }
  .pdfviewerr {
    margin: 0 auto;
    height: 50vh;
    width: 100%;
    border-radius: 1rem;
  }
  .buttoncv {
    width: 15rem;
  }
  .awardsclass {
    display: none;
  }

  .cards {
    margin-top: 5rem;
    display: grid;
    gap: 1rem;
    justify-content: center;
  }

  .card {
    scale: 0.9;
    color: white;
    overflow: hidden;
    height: 20rem;
    width: 100%;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: 0.2rem 0.2rem 0.8rem #0000007a;
  }

  .frontenddes {
    scale: 0.9;
    margin-top: 3rem;
    margin-left: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .skillcard {
    height: 5rem;
    width: 14rem;
    box-shadow: 0rem 0rem 0.4rem #0000007a;
    border-radius: 0.4rem;
    overflow: hidden;
    display: flex;
    color: #000;
  }
  .accdesbox2 {
    scale: 0.9;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .accdesbox {
    scale: 0.9;
  }

  .accordion-content1 {
    background-color: rgb(245, 245, 245);
    padding-top: 0rem;
  }
}

/* MOBILE mini screens */

@media screen and (max-width: 360px) {
  .pdfviewerr {
    margin: 0 auto;
    height: 50vh;
    width: 100%;
    border-radius: 1rem;
  }
  .buttoncv {
    width: 15rem;
  }
  .awardsclass {
    display: none;
  }

  .cards {
    margin-top: 5rem;
    display: grid;
    gap: 1rem;
    justify-content: center;
  }

  .card {
    scale: 0.9;
    color: white;
    overflow: hidden;
    height: 20rem;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: 0.2rem 0.2rem 0.8rem #0000007a;
  }

  .frontenddes {
    scale: 0.9;
    margin-top: 3rem;
    margin-left: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .skillcard {
    height: 5rem;
    width: 14rem;
    box-shadow: 0rem 0rem 0.4rem#0000007a;
    border-radius: 0.4rem;
    overflow: hidden;
    display: flex;
    color: #000;
  }

  .accdesbox2 {
    scale: 0.9;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .accdesbox {
    scale: 0.9;
  }

  .accordion-content1 {
    background-color: rgb(245, 245, 245);
    padding-top: 0rem;
  }

  .exp_accdesbox1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0rem;
  }

  .exp_frontend img {
    text-align: center;
    margin-top: 2rem;
  }

  .exp_frontenddes h2 {
    margin-left: 0rem;
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
  }

  .exp_frontenddes h6 {
    margin-left: 0rem;
    margin-bottom: 0.5rem;
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
  }

  .exp_accordion-title {
    margin: 0.3rem 2rem;
  }

  .exp_accordion-content2 {
    margin: 0rem 2rem;
  }
}

@media screen and (max-width: 1023px) {
  .awa1 {
    display: block;
  }

  .swipperr {
    height: 22rem;
    margin: 0 auto;
  }

  .swipperr .swipperr12 {
    height: 22rem;
    width: 100%;
    background-color: #fff;
    padding: 0 auto;
  }

  .lbox {
    padding-top: 1rem;
  }

  .swiper_image {
    margin: 1rem 0rem;
  }

  .swiper_image img {
    height: 20rem;
    object-fit: cover;
  }
  .Html5pic {
    height: 3.8rem;
    margin-top: 0.6rem;
    margin-left: 0rem;
  }

  .CSS3pic {
    height: 3.8rem;
    margin-top: 0.6rem;
    margin-left: 0rem;
  }

  .BSpic {
    height: 3.8rem;
    margin-top: 0.4em;
    margin-left: 0rem;
  }

  .RSpic {
    height: 3.8rem;
    margin-top: 0.4em;
    margin-left: 0rem;
  }

  .JSpic {
    height: 3.5rem;
    margin-top: 0.8rem;
    margin-left: 0rem;
  }

  .DSpic {
    height: 3.8rem;
    margin-top: 0.6rem;
    margin-left: 0rem;
  }

  .nodepic {
    height: 3.8rem;
    margin-top: 0.6rem;
    margin-left: 0rem;
  }

  .pythonpic {
    height: 3.8rem;
    margin-top: 0.6rem;
    margin-left: 0rem;
  }

  .APIpic {
    height: 3.8rem;
    margin-top: 0.6rem;
    margin-left: 0rem;
  }

  .Reactpic {
    height: 3.8rem;
    margin-top: 0.4em;
    margin-left: 0rem;
  }

  .Expresspic {
    height: 2rem;
    margin-top: 1em;
    margin-left: 0rem;
  }

  .Rspic {
    height: 4rem;
    margin-top: 0.5rem;
    margin-left: 0rem;
  }

  .Jquerypic {
    height: 3rem;
    margin-top: 1rem;
    margin-left: 0rem;
  }

  .tspic {
    height: 3rem;
    margin-top: 1rem;
    margin-left: 0rem;
  }

  .Ethereumpic {
    height: 3.8rem;
    margin-top: 0.6rem;
    margin-left: 0rem;
  }

  .Scpic {
    height: 3.5rem;
    margin-top: 0.8rem;
    margin-left: 0rem;
  }

  .Soliditypic {
    height: 4rem;
    margin-top: 0.5rem;
    margin-left: 0rem;
  }

  .VSCodepic {
    height: 3.8rem;
    margin-top: 0.4em;
    margin-left: 0rem;
  }
  .MongoDBpic {
    height: 3rem;
    margin-top: 1rem;
    margin-left: 0rem;
  }

  .SaaSpic {
    height: 4rem;
    margin-top: 0.6rem;
    margin-left: 0rem;
  }

  .BaaSpic {
    height: 3rem;
    margin-top: 1rem;
    margin-left: 0rem;
  }

  .Gitpic {
    height: 4rem;
    margin-left: 0rem;
    margin-top: 0.5rem;
  }

  .Clang {
    height: 3rem;
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .apip {
    height: 4rem;
    margin-left: 0rem;
    margin-top: 0.5rem;
  }

  .NJS {
    height: 4rem;
    margin-left: 0rem;
    margin-top: 0.5rem;
  }

  .py {
    height: 4rem;
    margin-left: 0rem;
    margin-top: 0.5rem;
  }
}
/* TABLET min width 768px and max width 1023px */

@media screen and (min-width: 781px) and (max-width: 1023px) {
  .pdfviewerr {
    margin: 0 auto;
    height: 60vh;
    width: 90%;
    border-radius: 1rem;
  }
  .buttoncv {
    margin-top: 0rem;
    width: 20rem;
  }

  .about_para_containermq {
    width: 70%;
  }

  .alice-image {
    height: 12rem;
    scale: 1;
  }

  .pic_me {
    height: 73%;
    width: 63%;
    border-radius: 2rem;
    margin: 23.5% 0% 0% 20%;
  }

  .wid_des {
    display: flex;
    padding: 5rem 5rem;
    justify-content: center;
    gap: 15%;
    margin-top: 5rem;
    margin-bottom: 0rem;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  .into_para_1 {
    margin-top: 7.5%;
    font-size: 1.7rem;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 2.5rem;
  }

  .accdesbox1 {
    display: flex;
    flex-direction: column;
  }

  .frontend h2 {
    margin-top: 2rem;
    margin-left: 0rem;
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
  }

  .frontend {
    width: 100%;
    padding-top: 1.5rem;
    padding-left: 0%;
  }

  .exp_frontend img {
    text-align: center;
    margin-top: 2rem;
  }

  .frontenddes {
    margin-top: 3rem;
    margin-left: 0rem;
    padding-left: 3rem;
    padding-right: 3rem;
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    zoom: 0.9;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .accordion-title {
    display: flex;
    margin: 0.3rem 1.5rem;
    font-size: 1.5rem;
    padding: 1.8rem;
    background-color: #f7f7f7;
    cursor: pointer;
    color: #000;
  }

  .accordion-content2 {
    background-color: rgb(245, 245, 245);
    padding-bottom: 5rem;
    margin: 0rem 1.5rem;
  }

  .accordion-content1 {
    background-color: rgb(245, 245, 245);
    margin: 0rem 1.5rem;
    padding-top: 0rem;
  }

  .accordion-content {
    background-color: rgb(245, 245, 245);
    margin: 0rem 1.5rem;
  }

  .accdesbox {
    display: none;
  }

  .mqhidden {
    display: block;
  }

  .langv1 {
    width: 100%;
    margin-top: 0.7rem;
    height: 4rem;
  }

  .langv1 h1 {
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
  }

  .languages {
    scale: 0.9;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    width: 100%;
    padding-bottom: 3rem;
  }

  .progressbar-wrapper {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .progressbar li {
    list-style-type: none;
    width: 25%;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #7d7d7d;
  }

  .langv3 {
    width: 100%;
    margin-left: 0%;
    margin-top: 0.5rem;
    justify-content: center;
  }

  .accdesbox2 {
    scale: 0.9;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .cards {
    padding: 2rem;
    align-items: center;
    margin-top: 5rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 4rem;
    column-gap: 0rem;
  }

  .card {
    position: relative;
    color: white;
    overflow: hidden;
    height: 20rem;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: 0.2rem 0.2rem 0.8rem #0000007a;
  }

  .view_more {
    margin-top: 3rem;
    cursor: pointer;
  }

  .view_less {
    margin-top: 3rem;
    cursor: pointer;
  }
}

@media screen and (min-width: 781px) and (max-width: 950px) {
  .alice-image {
    height: 12rem;
    scale: 0.8;
  }

  .about_container_me {
    display: none;
  }

  .exp_frontend img {
    text-align: center;
    margin-top: 2rem;
  }

  .about_container_memq {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-bottom: 5rem;
    margin-top: 7rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .intro_con_title1 {
    display: none;
  }

  .intro_con_titlemq {
    display: block;
    font-size: 3rem;
    font-weight: 200;
    font-family: "Josefin Sans", sans-serif;
    text-align: center;
  }
}

@media screen and (min-width: 781px) and (max-width: 850px) {
  .alice-image {
    height: 12rem;
    scale: 1;
  }
}
@media screen and (min-width: 951px) and (max-width: 1023px) {
  .resumecontainer {
    margin-top: 3rem;
    gap: 5rem;
  }

  .res_Accordion_123 {
    height: 100vh;
    padding-top: 15rem;
    padding-bottom: 10rem;
  }
}

@media screen and (min-width: 900px) and (max-width: 1023px) {
  .accdesbox1 {
    display: flex;
    flex-direction: column;
  }

  .frontend h2 {
    margin-top: 2rem;
    margin-left: 0rem;
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
  }

  .frontend {
    width: 100%;
    padding-top: 1.5rem;
    padding-left: 0%;
  }

  .exp_frontend img {
    text-align: center;
    margin-top: 2rem;
  }

  .frontenddes {
    margin-top: 3rem;
    margin-left: 0rem;
    padding-left: 3rem;
    padding-right: 3rem;
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    zoom: 1;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .accordion-title {
    display: flex;
    margin: 0.3rem 1.5rem;
    font-size: 1.5rem;
    padding: 1.8rem;
    background-color: #f7f7f7;
    cursor: pointer;
    color: #000;
  }

  .accordion-content2 {
    background-color: rgb(245, 245, 245);
    padding-bottom: 5rem;
    margin: 0rem 1.5rem;
  }

  .accordion-content1 {
    background-color: rgb(245, 245, 245);
    margin: 0rem 1.5rem;
  }

  .accordion-content {
    background-color: rgb(245, 245, 245);
    margin: 0rem 1.5rem;
  }

  .accdesbox {
    display: none;
  }

  .mqhidden {
    display: block;
  }

  .langv1 {
    width: 100%;
    margin-top: 0.7rem;
    height: 4rem;
  }

  .langv1 h1 {
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
  }

  .languages {
    scale: 1;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    width: 100%;
    padding-bottom: 3rem;
  }

  .progressbar-wrapper {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .progressbar li {
    list-style-type: none;
    width: 25%;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #7d7d7d;
  }

  .langv3 {
    width: 100%;
    margin-left: 0%;
    margin-top: 0.5rem;
    justify-content: center;
  }

  .accdesbox2 {
    scale: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .cards {
    padding: 5rem;
    align-items: center;
    margin-top: 3rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 6rem;
    column-gap: 0rem;
  }

  .card {
    position: relative;
    color: white;
    overflow: hidden;
    height: 20rem;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: 0.2rem 0.2rem 0.8rem #0000007a;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .awa1 {
    display: block;
  }
  .swipperr {
    height: 42rem;
    margin: 0 auto;
  }
  .swipperr .swipperr12 {
    height: 42rem;
    width: 100%;
    background-color: #fff;
    padding: 0 auto;
  }

  .lbox {
    padding-top: 10rem;
  }

  .swiper_image {
    margin: 1rem 0rem;
  }

  .swiper_image img {
    height: 40rem;
    object-fit: cover;
  }
  .wid_des {
    display: flex;
    padding: 5rem 5rem;
    justify-content: center;
    gap: 15%;
    margin-top: 5rem;
    margin-bottom: 0rem;
    width: 100%;
    height: 100%;
    align-items: center;
  }
  .pdfviewerr {
    margin: 0 auto;
    height: 60vh;
    width: 70%;
    border-radius: 1rem;
  }
  .buttoncv {
    margin-top: 0rem;
    width: 20rem;
  }
  .about_para_containermq {
    width: 70%;
  }
  .resumecontainer {
    margin-top: 3rem;
    gap: 5rem;
  }

  .res_Accordion_123 {
    height: 100vh;
    padding-bottom: 10rem;
  }

  .alice-image {
    height: 16rem;
    scale: 1;
  }

  .pic_me {
    height: 73%;
    width: 63%;
    border-radius: 2rem;
    margin: 20.5% 0% 0% 20%;
  }

  .accdesbox1 {
    display: flex;
    padding-left: 5rem;
    padding-bottom: 1rem;
  }

  .frontend h2 {
    margin-top: 2rem;
    margin-left: 0rem;
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: start;
  }

  .frontend {
    width: 30%;
    padding-top: 1.5rem;
    padding-left: 0%;
  }

  .exp_frontend img {
    text-align: center;
    margin-top: 2rem;
  }

  .frontenddes {
    margin-top: 3rem;
    margin-left: 0rem;
    padding-left: 3rem;
    padding-right: 3rem;
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    zoom: 1;
    justify-content: start;
  }

  .accordion-title {
    display: flex;
    margin: 0.3rem 1.5rem;
    font-size: 1.5rem;
    padding: 1.8rem;
    background-color: #f7f7f7;
    cursor: pointer;
    color: #000;
  }

  .accordion-content2 {
    background-color: rgb(245, 245, 245);
    padding-bottom: 5rem;
    margin: 0rem 1.5rem;
  }

  .accordion-content1 {
    background-color: rgb(245, 245, 245);
    margin: 0rem 1.5rem;
  }

  .accordion-content {
    background-color: rgb(245, 245, 245);
    margin: 0rem 1.5rem;
  }

  .accdesbox {
    display: block;
  }

  .mqhidden {
    display: none;
  }

  .accdesbox2 {
    scale: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .cards {
    scale: 1;
    padding: 2rem;
    align-items: start;
    margin-top: 5rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 4rem;
    column-gap: 3rem;
  }

  .card {
    position: relative;
    color: white;
    overflow: hidden;
    height: 20rem;
    width: 30rem;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: 0.2rem 0.2rem 0.8rem #0000007a;
  }

  .view_more {
    margin-top: 3rem;
    cursor: pointer;
  }

  .view_less {
    margin-top: 3rem;
    cursor: pointer;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .resumecontainer {
    margin-top: 3rem;
    gap: 5rem;
  }

  .alice-image {
    height: 12rem;
    scale: 1;
  }
}
@media screen and (min-width: 1215px) and (max-width: 1250px) {
  .resumecontainer {
    margin-top: 3rem;
    gap: 23rem;
  }

  .pic_me {
    height: 73%;
    width: 63%;
    border-radius: 2rem;
    margin: 19.5% 0% 0% 20%;
  }

  .accdesbox {
    display: block;
  }

  .mqhidden {
    display: none;
  }

  .accdesbox2 {
    scale: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .cards {
    scale: 1;
    padding: 2rem;
    align-items: start;
    margin-top: 5rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 4rem;
    column-gap: 3rem;
  }

  .card {
    position: relative;
    color: white;
    overflow: hidden;
    height: 20rem;
    width: 35rem;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: 0.2rem 0.2rem 0.8rem #8000007a;
  }

  .view_more {
    margin-top: 3rem;
    cursor: pointer;
  }

  .view_less {
    margin-top: 3rem;
    cursor: pointer;
  }

  .exp_frontend img {
    text-align: center;
    margin-top: 2rem;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1366px) {
  .resumecontainer {
    margin-top: 3rem;
    gap: 5rem;
  }

  .pic_me {
    height: 73%;
    width: 63%;
    border-radius: 2rem;
    margin: 16.5% 0% 0% 20%;
  }

  .cards {
    scale: 1;
    padding: 2rem;
    align-items: start;
    margin-top: 5rem;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    row-gap: 4rem;
    column-gap: 3rem;
  }

  .card {
    position: relative;
    color: white;
    overflow: hidden;
    height: 20rem;
    width: 35rem;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: 0.2rem 0.2rem 0.8rem #0000007a;
  }

  .exp_frontend img {
    text-align: center;
    margin-top: 2rem;
  }
}

@media screen and (min-width: 1301px) and (max-width: 1366px) {
  .pic_me {
    height: 73%;
    width: 63%;
    border-radius: 2rem;
    margin: 15% 0% 0% 20%;
  }

  .exp_frontend img {
    text-align: center;
    margin-top: 2rem;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1700px) {
  .wid_des {
    display: flex;
    padding: 5rem 5rem;
    justify-content: center;
    gap: 15%;
    margin-top: 5rem;
    margin-bottom: 0rem;
    width: 100%;
    height: 100%;
    align-items: center;
  }
}

@media screen and (min-width: 1367px) {
  .pdfviewerr {
    margin: 0 auto;
    height: 60vh;
    width: 60%;
    border-radius: 1rem;
  }
  .buttoncv {
    margin-top: 0rem;
    width: 20rem;
  }
  .about_para_containermq {
    width: 60%;
  }
  .pic_me {
    height: 73%;
    width: 63%;
    border-radius: 2rem;
    margin: 14% 0% 0% 20%;
  }

  .accdesbox1 {
    display: flex;
    padding-left: 5rem;
    padding-bottom: 1rem;
  }

  .frontend h2 {
    margin-top: 2rem;
    margin-left: 0rem;
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: start;
  }

  .frontend {
    width: 30%;
    padding-top: 1.5rem;
    padding-left: 0%;
  }

  .exp_frontend img {
    text-align: center;
    margin-top: 2rem;
  }

  .frontenddes {
    margin-top: 3rem;
    margin-left: 0rem;
    padding-left: 3rem;
    padding-right: 3rem;
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    zoom: 1;
    justify-content: start;
  }

  .accordion-title {
    display: flex;
    margin: 0.3rem 1.5rem;
    font-size: 1.5rem;
    padding: 1.8rem;
    background-color: #f7f7f7;
    cursor: pointer;
    color: #000;
  }

  .accordion-content2 {
    background-color: rgb(245, 245, 245);
    padding-bottom: 5rem;
    margin: 0rem 1.5rem;
  }

  .accordion-content1 {
    background-color: rgb(245, 245, 245);
    margin: 0rem 1.5rem;
  }

  .accordion-content {
    background-color: rgb(245, 245, 245);
    margin: 0rem 1.5rem;
  }

  .accdesbox {
    display: block;
  }

  .mqhidden {
    display: none;
  }

  .accdesbox2 {
    scale: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .cards {
    scale: 1;
    padding: 2rem;
    align-items: start;
    margin-top: 5rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 4rem;
    column-gap: 3rem;
  }

  .card {
    position: relative;
    color: white;
    overflow: hidden;
    height: 20rem;
    width: 38rem;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: 0.2rem 0.2rem 0.8rem #0000007a;
  }

  .view_more {
    margin-top: 3rem;
    cursor: pointer;
  }

  .view_less {
    margin-top: 3rem;
    cursor: pointer;
  }
}

@media screen and (min-width: 1367px) {
  .resumecontainer {
    margin-top: 5rem;
    gap: 5rem;
  }

  .res_Accordion_123 {
    height: 100vh;
    padding-bottom: 15rem;
  }

  .alice-image {
    height: 20rem;
    scale: 1;
  }
  .awa1 {
    display: block;
  }
  .swipperr {
    height: 62rem;
    margin: 0 auto;
  }

  .swiper_image {
    margin: 1rem 0rem;
  }

  .swiper_image img {
    height: 60rem;
    object-fit: cover;
  }
  .swipperr .swipperr12 {
    height: 62rem;
    width: 100%;
    background-color: #fff;
    padding: 0 auto;
  }

  .lbox {
    padding-top: 13rem;
  }
}

/* RESPONSIVE SECTION ENDS */
